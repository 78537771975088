import React from 'react';
import SEO from '../../components/SEO';
import Calendar from '../../components/Calendar';
import Layout from '../../layouts/index';

const Program = () => (
  <Layout bodyClass="page-program">
    <SEO title="Program" />
    <div className="intro pb-4">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h1>Program</h1>
            <p>For our 2nd batch of the startups we redesigned the program to help the startups move faster and more efficient towards their goal.</p>
            <p>The new program follows the Getting Things Done principles and pushes the startups to get to their next target faster than usual.</p>
          </div>
        </div>
        <div className="row pt-15">
          <div className="col-12">
            <Calendar />
          </div>
        </div>
      </div>
    </div>
  </Layout>
);

export default Program;
