const firstMonth = [
  {
    title: 'Welcome',
    allDay: true,
    start: new Date(2020, 1, 3),
    end: new Date(2020, 1, 3),
    desc: `<ul>
             <li>Welcome presentation</li>
             <li>Startup presentations</li>
             <li>Startup interviews</li>
           </ul>`,
  },
  {
    title: 'Product/presentation improvements',
    allDay: true,
    start: new Date(2020, 1, 4),
    end: new Date(2020, 1, 4),
    desc: `<ul>
             <li>Continuing the in-depth startup interviews</li>
             <li>Product descriptions</li>
             <li>Improving the startup presentations</li>
           </ul>`,
  },
  {
    backgroundColor: '#bd4e41',
    title: 'Online presence',
    allDay: true,
    start: new Date(2020, 1, 5),
    end: new Date(2020, 1, 5),
    desc: `<ul>
             <li>Analysis of startups' websites</li>
             <li>Start working on the website (if it doesn't exist)</li>
             <li>Use product messages created earlier</li>
             <li><b>Keynote by <a href="https://boogle.com" target="_blank">Boogle</a></b></li>
           </ul>`,
  },
  {
    title: 'Improvement on marketing/online presence',
    allDay: true,
    start: new Date(2020, 1, 6),
    end: new Date(2020, 1, 6),
    desc: `<ul>
             <li>Further iteration on the marketing</li>
             <li>Further iteration on the online presence</li>
           </ul>`,
  },
  {
    title: 'Weekly startup presentations',
    backgroundColor: '#F0C6AB',
    allDay: true,
    start: new Date(2020, 1, 7),
    end: new Date(2020, 1, 7),
    desc: `<ul>
             <li>Presenting the work done</li>
             <li><b>Mentor's dinner</b></li>
           </ul>`,
  },
  {
    title: 'Building products on AWS',
    backgroundColor: '#bd4e41',
    allDay: true,
    start: new Date(2020, 1, 10),
    end: new Date(2020, 1, 10),
    desc: `<ul>
             <li><b><a href="https://aws.amazon.com" target="_blank">AWS</a> Office Visit</b></li>
             <li>Building Products on AWS</li>
           </ul>`,
  },
  {
    title: 'Work on anything important',
    allDay: true,
    start: new Date(2020, 1, 11),
    end: new Date(2020, 1, 11),
    desc: `<ul>
             <li>Feel free to work on anything important</li>
           </ul>`,
  },
  {
    title: 'Agile',
    backgroundColor: '#bd4e41',
    allDay: true,
    start: new Date(2020, 1, 12),
    end: new Date(2020, 1, 12),
    desc: `<ul>
             <li>Agile Product Planning</li>
             <li>Agile Software Development</li>
           </ul>`,
  },
  {
    title: 'Lean startup',
    backgroundColor: '#bd4e41',
    allDay: true,
    start: new Date(2020, 1, 13),
    end: new Date(2020, 1, 13),
    desc: `<ul>
             <li><b><a href="https://spenmo.com/" target="_blank">Spenmo</a> Keynote</b></li>
             <li>Lean Startup Methodology</li>
             <li>Value Proposition Canvas</li>
           </ul>`,
  },
  {
    title: 'Company incorporation',
    backgroundColor: '#bd4e41',
    allDay: true,
    start: new Date(2020, 1, 14),
    end: new Date(2020, 1, 14),
    desc: `<ul>
             <li><b><a href="https://sleek.sg/" target="_blank">Sleek</a> Keynote</b></li>
             <li>Get together</li>
           </ul>`,
  },
  {
    title: 'Marketing Strategy',
    allDay: true,
    start: new Date(2020, 1, 17),
    end: new Date(2020, 1, 17),
    desc: `<ul>
             <li>Working out the marketing strategy</li>
             <li>Start pushing the product on marketing channels</li>
           </ul>`,
  },
  {
    title: 'Website improvements',
    allDay: true,
    start: new Date(2020, 1, 18),
    end: new Date(2020, 1, 18),
    desc: `<ul>
             <li>Improving the website</li>
           </ul>`,
  },
  {
    title: 'Marketing Keynote',
    backgroundColor: '#bd4e41',
    allDay: true,
    start: new Date(2020, 1, 19),
    end: new Date(2020, 1, 19),
    desc: `<ul>
             <li>Marketing analysis for every startup</li>
             <li>Keynote on marketing</li>
           </ul>`,
  },
  {
    title: 'BMC and Marketing',
    allDay: true,
    start: new Date(2020, 1, 20),
    end: new Date(2020, 1, 20),
    desc: `<ul>
             <li>Working on BMC</li>
             <li>Continue working on marketing</li>
           </ul>`,
  },
  {
    title: 'Presentations',
    backgroundColor: '#F0C6AB',
    allDay: true,
    start: new Date(2020, 1, 21),
    end: new Date(2020, 1, 21),
    desc: `<ul>
             <li>Startup Presentations on Marketing/Website/BMC</li>
             <li>Get together</li>
           </ul>`,
  },
  {
    title: 'Product and Market',
    allDay: true,
    start: new Date(2020, 1, 24),
    end: new Date(2020, 1, 24),
    desc: `<ul>
             <li>Market Validation Techniques</li>
             <li>Product Market Fit</li>
           </ul>`,
  },
  {
    title: 'Market Validation',
    allDay: true,
    start: new Date(2020, 1, 25),
    end: new Date(2020, 1, 25),
    desc: `<ul>
             <li>Market Validation Techniques</li>
           </ul>`,
  },
  {
    backgroundColor: '#bd4e41',
    title: 'Market Validation',
    allDay: true,
    start: new Date(2020, 1, 26),
    end: new Date(2020, 1, 26),
    desc: `<ul>
             <li>Market Validation Techniques</li>
             <li><a href="https://www.grab.com/sg/pay/" target="_blank">Grab Pay</a> - Keynote</li>
           </ul>`,
  },
  {
    title: 'Product Iteration',
    allDay: true,
    start: new Date(2020, 1, 27),
    end: new Date(2020, 1, 27),
    desc: `<ul>
             <li>Working on the product</li>
           </ul>`,
  },
  {
    title: 'Product Presentation',
    backgroundColor: '#F0C6AB',
    allDay: true,
    start: new Date(2020, 1, 28),
    end: new Date(2020, 1, 28),
    desc: `<ul>
             <li>Presentation of the results</li>
             <li>Get Together</li>
           </ul>`,
  },
];

const secondMonth = [
  {
    backgroundColor: '#bd4e41',
    title: 'Pitchspot Presentation',
    allDay: true,
    start: new Date(2020, 2, 2),
    end: new Date(2020, 2, 2),
    desc: `<ul>
             <li>Work on the product</li>
             <li><a href="https://pitchspot.co/" target="_blank">Pitchspot</a> Presentation</li>
             <li>Introduction to <a href="https://pitchspot.co/" target="_blank">Pitchspot</a> platform</li>
           </ul>`,
  },
  {
    title: 'Business Plan',
    allDay: true,
    start: new Date(2020, 2, 3),
    end: new Date(2020, 2, 3),
    desc: `<ul>
             <li>Business Plan</li>
           </ul>`,
  },
  {
    backgroundColor: '#bd4e41',
    title: 'Payments Consultation',
    allDay: true,
    start: new Date(2020, 2, 4),
    end: new Date(2020, 2, 4),
    desc: `<ul>
             <li>Product Work</li>
             <li>Consultation with Peter Hueftlein</li>
           </ul>`,
  },
  {
    title: 'Work on the Product',
    allDay: true,
    start: new Date(2020, 2, 5),
    end: new Date(2020, 2, 5),
    desc: `<ul>
             <li>Product Work</li>
             <li>Marketing Work</li>
             <li>Business Plan Work</li>
           </ul>`,
  },
  {
    backgroundColor: '#bd4e41',
    title: 'Product Consultation',
    allDay: true,
    start: new Date(2020, 2, 6),
    end: new Date(2020, 2, 6),
    desc: `<ul>
             <li>Product Work</li>
             <li>Consultation with Igor</li>
           </ul>`,
  },
  {
    title: 'Design Consultation',
    allDay: true,
    start: new Date(2020, 2, 9),
    end: new Date(2020, 2, 9),
    desc: `<ul>
             <li>Product Work</li>
             <li>Consultation with Bart</li>
           </ul>`,
  },
  {
    title: 'Design Sprint',
    allDay: true,
    start: new Date(2020, 2, 10),
    end: new Date(2020, 2, 10),
    desc: `<ul>
             <li>Design work</li>
             <li>Improving the design</li>
           </ul>`,
  },
  {
    title: 'A/B Testing',
    allDay: true,
    start: new Date(2020, 2, 11),
    end: new Date(2020, 2, 11),
    desc: `<ul>
             <li>A/B Testing</li>
           </ul>`,
  },
  {
    title: 'Symbo Masterclass',
    backgroundColor: '#bd4e41',
    allDay: true,
    start: new Date(2020, 2, 12),
    end: new Date(2020, 2, 12),
    desc: `<ul>
             <li>Product and design work</li>
             <li><a href="https://www.symboinsurance.sg/" target="_blank">Symbo</a> Masterclass by Kangwei Wong</li>
           </ul>`,
  },
  {
    title: 'Design Work Presentation',
    backgroundColor: '#F0C6AB',
    allDay: true,
    start: new Date(2020, 2, 13),
    end: new Date(2020, 2, 13),
    desc: `<ul>
             <li>Presentation Prep</li>
             <li>Presentations of the design week results</li>
           </ul>`,
  },
  {
    title: 'Worknetics workshop',
    backgroundColor: '#bd4e41',
    allDay: true,
    start: new Date(2020, 2, 16),
    end: new Date(2020, 2, 16),
    desc: `<ul>
             <li>Product work</li>
             <li><a href="https://www.worknetics.net/" target="_blank">Worknetics</a> workshop by Ernest Teh</li>
           </ul>`,
  },
  {
    title: 'MVP',
    allDay: true,
    start: new Date(2020, 2, 17),
    end: new Date(2020, 2, 17),
    desc: `<ul>
             <li>Work on the MVP</li>
           </ul>`,
  },
  {
    title: 'MVP/Product development',
    allDay: true,
    start: new Date(2020, 2, 18),
    end: new Date(2020, 2, 18),
    desc: `<ul>
             <li>Work on the MVP</li>
             <li>Product development</li>
           </ul>`,
  },
  {
    title: 'Dean Consultation',
    backgroundColor: '#bd4e41',
    allDay: true,
    start: new Date(2020, 2, 19),
    end: new Date(2020, 2, 19),
    desc: `<ul>
             <li>Work on the MVP</li>
             <li>Dean Consultation</li>
           </ul>`,
  },
  {
    title: 'Product Development Presentation',
    backgroundColor: '#F0C6AB',
    allDay: true,
    start: new Date(2020, 2, 20),
    end: new Date(2020, 2, 20),
    desc: `<ul>
             <li>Presentation Prep</li>
             <li>Presentations of product development results</li>
             <li>Sports and games</li>
           </ul>`,
  },
  {
    title: 'Endeavour Ventures Workshop',
    backgroundColor: '#bd4e41',
    allDay: true,
    start: new Date(2020, 2, 23),
    end: new Date(2020, 2, 23),
    desc: `<ul>
             <li>Product work</li>
             <li><a href="https://www.endeavour.ventures/" target="_blank">Endeavour Ventures</a> workshop by Sam Gibb</li>
           </ul>`,
  },
  {
    title: 'Tritaurian Capital',
    backgroundColor: '#bd4e41',
    allDay: true,
    start: new Date(2020, 2, 24),
    end: new Date(2020, 2, 24),
    desc: `<ul>
             <li>Work on the MVP</li>
             <li><a href="https://www.tritauriancapital.com/" target="_blank">Tritaurian Capital</a> workshop by Yonyi (remote)</li>
           </ul>`,
  },
  {
    title: 'Raising Funds',
    backgroundColor: '#bd4e41',
    allDay: true,
    start: new Date(2020, 2, 25),
    end: new Date(2020, 2, 25),
    desc: `<ul>
             <li>Angel funding by Vidit</li>
             <li>Product market fit by Daniel</li>
           </ul>`,
  },
  {
    title: 'Sixthirty Ventures',
    backgroundColor: '#bd4e41',
    allDay: true,
    start: new Date(2020, 2, 26),
    end: new Date(2020, 2, 26),
    desc: `<ul>
             <li>Work on the MVP</li>
             <li><a href="https://sixthirty.co/" target="_blank">Sixthirty Ventures</a> workshop by Evan Thorpe</li>
           </ul>`,
  },
  {
    title: 'Product Work',
    allDay: true,
    start: new Date(2020, 2, 27),
    end: new Date(2020, 2, 27),
    desc: `<ul>
             <li>Product Work</li>
             <li>Sports and games</li>
           </ul>`,
  },
  {
    title: 'Improving product',
    allDay: true,
    start: new Date(2020, 2, 30),
    end: new Date(2020, 2, 30),
    desc: `<ul>
             <li>Work on the product</li>
           </ul>`,
  },
  {
    title: 'Improving Product',
    allDay: true,
    start: new Date(2020, 2, 31),
    end: new Date(2020, 2, 31),
    desc: `<ul>
             <li>Raising Capital</li>
             <li>Product Work</li>
           </ul>`,
  },
];

const thirdMonth = [
  {
    title: 'Product and Capital',
    allDay: true,
    start: new Date(2020, 3, 1),
    end: new Date(2020, 3, 1),
    desc: `<ul>
             <li>Raising Capital</li>
             <li>Product work</li>
           </ul>`,
  },
  {
    title: 'Product Consultation',
    backgroundColor: '#bd4e41',
    allDay: true,
    start: new Date(2020, 3, 2),
    end: new Date(2020, 3, 2),
    desc: `<ul>
             <li>Raising Capital</li>
             <li>Product Consultation by Igor</li>
           </ul>`,
  },
  {
    title: 'Product Work',
    allDay: true,
    start: new Date(2020, 3, 3),
    end: new Date(2020, 3, 3),
    desc: `<ul>
             <li>Work on the MVP</li>
             <li>Sports and Games</li>
           </ul>`,
  },
  {
    title: 'Deutsche Bank Visit',
    backgroundColor: '#bd4e41',
    allDay: true,
    start: new Date(2020, 3, 6),
    end: new Date(2020, 3, 6),
    desc: `<ul>
             <li>Work on the product</li>
             <li>Work on the pitch</li>
             <li>Visiting the Deutsche Bank Lab</li>
           </ul>`,
  },
  {
    title: 'Growth Hacking',
    allDay: true,
    start: new Date(2020, 3, 7),
    end: new Date(2020, 3, 7),
    desc: `<ul>
             <li>How to grow your product</li>
           </ul>`,
  },
  {
    title: 'Business Development',
    allDay: true,
    start: new Date(2020, 3, 8),
    end: new Date(2020, 3, 8),
    desc: `<ul>
             <li>Business Development</li>
           </ul>`,
  },
  {
    title: 'Product/Marketing/Growth',
    allDay: true,
    start: new Date(2020, 3, 9),
    end: new Date(2020, 3, 9),
    desc: `<ul>
             <li>Improving the product</li>
             <li>Improving marketing</li>
             <li>Work on the growth</li>
           </ul>`,
  },
  {
    title: 'Good Friday',
    backgroundColor: '#DF5552',
    allDay: true,
    start: new Date(2020, 3, 10),
    end: new Date(2020, 3, 10),
    desc: `<ul>
             <li>Public Holiday</li>
           </ul>`,
  },
  {
    title: 'Pitchlab Academy',
    backgroundColor: '#bd4e41',
    allDay: true,
    start: new Date(2020, 3, 13),
    end: new Date(2020, 3, 13),
    desc: `<ul>
             <li>Work on the product</li>
             <li><a href="" target="_blank">PitchLAB</a> academy workshop by Rodrigo</li>
           </ul>`,
  },
  {
    title: 'Pitch Refinement',
    allDay: true,
    start: new Date(2020, 3, 14),
    end: new Date(2020, 3, 17),
    desc: `<ul>
             <li>Refining the pitch</li>
             <li>Preparing the presentation</li>
           </ul>`,
  },
  {
    title: 'Pitch night',
    backgroundColor: '#F0C6AB',
    allDay: true,
    start: new Date(2020, 3, 17),
    end: new Date(2020, 3, 17),
    desc: `<ul>
             <li>Rehearsing the pitches</li>
             <li>Pitch night</li>
           </ul>`,
  },
  {
    title: 'Demo Day Preps',
    allDay: true,
    start: new Date(2020, 3, 20),
    end: new Date(2020, 3, 24),
    desc: `<ul>
             <li>Pitch refinement</li>
             <li>Demo Day preparations</li>
           </ul>`,
  },
  {
    title: 'Demo Day',
    backgroundColor: '#F0C6AB',
    allDay: true,
    start: new Date(2020, 3, 24),
    end: new Date(2020, 3, 24),
    desc: `<ul>
             <li>Demo Day</li>
           </ul>`,
  },
];

const events = firstMonth
  .concat(secondMonth)
  .concat(thirdMonth);

export default events;
