import React from 'react';
import PropTypes from 'prop-types';

const Modal = ({ closeModal, show, content }) => {
  const styles = show
    ? { display: 'block' }
    : { display: 'none' };
  return (
    <>
      <div
        className="modal"
        role="dialog"
        style={styles}
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{ content.title }</h5>
              <button type="button"
                      className="close"
                      onClick={closeModal}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div className="modal-body"
              dangerouslySetInnerHTML={{ __html: content.desc }}
            />

            <div className="modal-footer">
              <button type="button"
                      className="btn btn-secondary"
                      onClick={closeModal}>
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

Modal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  content: PropTypes.shape({
    title: PropTypes.string,
    content: PropTypes.string,
  }).isRequired,
};

export default Modal;
