import React from 'react';
import { Calendar, momentLocalizer, Views } from 'react-big-calendar';
import moment from 'moment';
import PropTypes from 'prop-types';
import Modal from './Modal';
import events from '../data/events';
import 'react-big-calendar/lib/css/react-big-calendar.css';

// in order to start the week on Monday
// instead of Sunday
moment.locale('en-US', {
  week: {
    dow: 1,
  },
});

const localizer = momentLocalizer(moment);

function Event({ event: { title, desc } }) {
  return (
    <span>
      <strong>{title}</strong>
      <div dangerouslySetInnerHTML={ { __html: desc } } />
    </span>
  );
}

Event.propTypes = {
  event: PropTypes.shape({
    title: PropTypes.string,
    desc: PropTypes.string,
  }).isRequired,
};

function EventAgenda({ event: { title, desc } }) {
  return (
    <span>
      <h2 className="pt-3">{title}</h2>
      <div dangerouslySetInnerHTML={ { __html: desc } } />
    </span>
  );
}

EventAgenda.propTypes = {
  event: PropTypes.shape({
    title: PropTypes.string,
    desc: PropTypes.string,
  }).isRequired,
};

class CalendarComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      calendarEvent: {
        title: '',
        body: '',
      },
      show: false,
    };
  }

  showEvent = (calendarEvent) => {
    this.setState({
      show: true,
      calendarEvent,
    });
  }

  hideModal = () => {
    this.setState({
      show: false,
    });
  }

  render() {
    return (
      <>
        <Calendar
          selectable
          localizer={localizer}
          events={events}
          startAccessor="start"
          endAccessor="end"
          defaultView={Views.MONTH}
          scrollToTime={new Date(2020, 0, 1, 6)}
          onSelectEvent={event => this.showEvent(event)}
          style={{ height: 500 }}
          components={{
            event: Event,
            agenda: {
              event: EventAgenda,
            },
          }}
          eventPropGetter={
            (event) => {
              const myStyle = {
                backgroundColor: '#4fb5b2',
                color: 'black',
                borderRadius: '0px',
                border: 'none',
              };

              if (event.backgroundColor) {
                myStyle.backgroundColor = event.backgroundColor;
              }

              return {
                className: '',
                style: myStyle,
              };
            }
          }
        />
        <Modal
          content={this.state.calendarEvent}
          show={this.state.show}
          closeModal={this.hideModal}
        />
      </>
    );
  }
}

export default CalendarComponent;
